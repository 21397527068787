import moment from 'moment';
import Link from 'next/link';
import { strapiApiUrlImage } from 'src/config';
import type { Article } from '../../../types/article';
import type { ILinkProps } from '../../../types/types';
import { cn } from '../../../utils/cn';
import { ArticleTagsBlock } from './tag';

type Options = {
  article: Pick<
    Article,
    'image' | 'short_text' | 'title' | 'tags' | 'publishedAt'
  >;
};

export default function ArticleCard({
  className,
  article,
  ...props
}: ILinkProps & Options) {
  return (
    <Link
      {...props}
      className={cn(
        'rounded-xl flex flex-col',
        'shadow-[0px_2px_8px_-3px_rgba(15,25,40,0.10),0px_6px_20px_-5px_rgba(12,39,36,0.10)] hover:shadow-[0px_2px_8px_-3px_rgba(15,25,40,0.10),0px_6px_24px_-5px_rgba(12,39,36,0.25)] focus:shadow-[0px_2px_8px_-3px_rgba(15,25,40,0.10),0px_6px_24px_-5px_rgba(12,39,36,0.25)]',
        'transition-shadow duration-[120ms] ease-out',
        className
      )}
    >
      <div className="h-[188px] shrink-0 overflow-hidden rounded-t-xl bg-neutral-300">
        <img
          src={`${strapiApiUrlImage}${article.image.data?.attributes.url}`}
          alt=""
          className="size-full object-cover"
        />
      </div>
      <div className="flex h-full min-h-0 flex-col px-6 pb-6 pt-4 max-sm:p-4">
        <div className="flex flex-col gap-2 overflow-hidden">
          <div className="whitespace-normal text-title-2-semibold text-neutral-950 max-sm:text-label-1-semibold">
            {article.title}
          </div>
          <div className="line-clamp-3 whitespace-normal text-label-2-regular text-neutral-600 max-sm:h-[3.75rem]">
            {article.short_text}
          </div>
        </div>
        <div className="mt-auto flex gap-2">
          {article.tags && (
            <ArticleTagsBlock tags={article.tags} className="pt-4" />
          )}
          <div className="self-center pt-4 text-label-2-regular text-neutral-600 lg:hidden">
            {moment(article.publishedAt).format('MMM. D, YYYY')}
          </div>
        </div>
      </div>
    </Link>
  );
}
