import React from 'react';
import type { ILinkProps } from '../../../types/types';
import { cn } from '../../../utils/cn';
import { GreenOutlinedButtonLink } from '../../button/green-outlined-button';

type IArticleTag = {
  payload: string;
};

type IArticleTagsBlock = {
  tags: string[];
  className?: string;
};

export function ArticleTagsBlock({ tags, className }: IArticleTagsBlock) {
  return (
    <div className={cn('flex flex-row space-x-1', className)}>
      {tags.map((tag, key) => (
        <ArticleTag payload={tag} key={key} href={'/'} />
      ))}
    </div>
  );
}
export function ArticleTag({ payload, ...props }: ILinkProps & IArticleTag) {
  return (
    <GreenOutlinedButtonLink
      {...props}
      payload={payload}
      className="rounded-[12.5rem] px-3 py-1"
    />
  );
}
